<template>
  <div>
    <Card>
      <template #header>İhale Ön Teklif Kayıtları</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="addFileModal = true"
              >Yeni Teklif</b-button
            ></b-form-group
          >
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Ön Teklif Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.collateral_deposited="{ item }">
        <div>
          <b-badge v-if="item.collateral_deposited === '1'" variant="success"
            >Evet</b-badge
          >
          <b-badge v-else variant="danger">Hayır</b-badge>
        </div>
      </template>
      <template v-slot:item.offer_status="{ item }">
        <div>
          <b-badge v-if="item.offer_status === '1'" variant="primary"
            >Yeni</b-badge
          >
          <b-badge v-else variant="danger">Bankaya İletilecek</b-badge>
          <b-badge v-else variant="success">Onaylandı</b-badge>
          <b-badge v-else variant="danger">Onaylanmadı</b-badge>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end">
          <button class="btn p-0">
            <i class="flaticon-eye text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon-download text-dark text-md"></i>
          </button>
        </div>
      </template>
    </List>
    <b-modal v-model="addFileModal" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i> Ön Teklif Ekle</h4>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="bidder">Teklif Sahibi </label>
            <select name="bidder" id="bidder" class="form-control" v-model="bidder">
              <option value="" selected>Seçiniz</option>
              <option value="1">a</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="order_date">Teklif Tarihi</label>
            <input type="date" class="form-control" id="order_date" name="order_date" v-model="order_date" />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="bid_amount">Teklif Tutarı</label>
            <input type="text" class="form-control" id="bid_amount" name="bid_amount" v-model="bid_amount"/>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="offer_description">Teklif Açıklaması</label>
            <textarea name="offer_description" id="offer_description" rows="3" class="form-control" v-model="offer_description"></textarea>
          </div>
        </div>
        <div class="col-12">
          <b-form-checkbox
          name="check-button"
          switch
          class="mb-5"
          v-model="collateral_deposited"
        >
          Teminat Yatırıldı Mı?
        </b-form-checkbox>
        </div>
        <div class="card-sub-header w-100">Teklif Durumu</div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="">Teklif Durumu</label>
            <select name="offer_status" id="offer_status" class="form-control" v-model="offer_status">  
              <option value="" selected>Seçiniz</option>
              <option value="1">a</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="">Banka Teklif Tutarı</label>
            <input type="text" class="form-control" name="bank_offer" id="bank_offer" v-model="bank_offer" />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="offer_description">Banka Teklif Tutarı Açıklama</label>
            <textarea class="form-control" rows="4" name="offer_description" id="offer_description"></textarea>
          </div>
        </div>
        <div class="col-12">
          <b-form-group class="mb-0">
            <label for="file"> Banka Maili </label>
            <b-form-file
              id="file"
              class="rounded-sm"
              placeholder="Dosya seçiniz"
              drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
            ></b-form-file>
          </b-form-group>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addFileModal = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="save" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "systemUsers",
  data() {
    return {
      addFileModal: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Teklif Sahibi", value: "bidder" },
        { text: "Teklif Tarihi", value: "order_date" },
        { text: "Teklif Tutarı", value: "bid_amount" },
        { text: "Banka Tutarı", value: "bank_offer" },
        { text: "Teklif Durumu", value: "offer_status" },
        { text: "Teminat Yatırıldı mı?", value: "collateral_deposited" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",

      bidder: "",
      order_date: "",
      bid_amount: "",
      offer_description: "",
      collateral_deposited: "",
      offer_status: "",
      bank_offer: "",
      offer_description: "",
    };
  },

  created() {
    this.getList();
  },
  methods: {
    save() {
      let data = {
        bidder: this.bidder,
        order_date: moment(this.order_date).format('DD/MM/YYYY'),
        bid_amount: this.bid_amount,
        offer_description: this.offer_description,
        collateral_deposited: this.collateral_deposited,
        offer_status: this.offer_status,
        bank_offer: this.bank_offer,
        offer_description: this.offer_description,
        user_token: this.myUser,
        advert_id: this.$route.params.id
      };
      this.$ApiService
      .post("advert/preoffer",data)
      .then((res)=>{
        this.$generateNotification(this,"success","Teklif Eklenmiştir.")
        this.getList()
        this.bidder= "",
        this.order_date= "",
        this.bid_amount= "",
        this.offer_description= "",
        this.collateral_deposited= "",
        this.offer_status= "",
        this.bank_offer= "",
        this.offer_description= "",
        this.addFileModal = false;
      })
      .catch((err) => {
        this.$generateNotification(this,"error","Teklif Eklenemedi.")
      })
     
    },
    getList() {
      this.loading = true;
      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `advert/preoffer?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&advert_id=${
        this.$route.params.id
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }
      this.$dbFunctions
        .get(query)
        .then((res) => {
          if (res.status.code == 200) {
            this.items = res.response.data;
            this.totalItems = res.response.data.length;

            this.pagination = {
              current_page: 1,
              total_pages: Math.ceil(res.response.data.length / 10),
              total_items: this.totalItems,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
<style lang="scss" scoped>
.card-sub-header {
  background-color: $dveb-divider;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 1rem 0;
}
</style>
